<div class="text-gray-500">
  @if (profile) {
  <p><strong>Name: </strong> {{ profile.name }}</p>
  <p><strong>Email: </strong> {{ profile.email }}</p>
  <p><strong>Id: </strong> {{ profile.id }}</p>
  <p><strong>Roles: </strong></p>
  @for (role of profile.roles; track $index) {
  <p>&NonBreakingSpace; {{ role }}</p>
  } }
</div>
