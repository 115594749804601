import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityService } from '../../services/security.service';
import { User } from '../../models/user.model';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [],
  standalone: true,
  imports: [CommonModule],
})
export class HomeComponent implements OnInit {
  currentUser!: User;

  constructor(
    private securityService: SecurityService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.securityService
      .getCurrentUser()
      .then((user) => (this.currentUser = user))
      .catch((err) => this.notificationsService.error(err));
  }
}
