import { Routes } from '@angular/router';
import { FailedComponent } from './components/failed/failed.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MsalGuard } from '@azure/msal-angular';
import { PermissionsGuard } from './guards/PermissionsGuard';

export const appRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    path: 'applications',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/applications/applications.component').then(
        (c) => c.ApplicationsComponent
      ),
  },
  {
    path: 'application',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/application/application.component').then(
        (c) => c.ApplicationComponent
      ),
  },
  {
    path: 'application/:id',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/application/application.component').then(
        (c) => c.ApplicationComponent
      ),
  },
  {
    path: 'roles',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/roles/roles.component').then(
        (c) => c.RolesComponent
      ),
  },
  {
    path: 'role',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/role/role.component').then((c) => c.RoleComponent),
  },
  {
    path: 'role/:id',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/role/role.component').then((c) => c.RoleComponent),
  },
  {
    path: 'permissions',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/role-permissions/role-permissions.component').then(
        (c) => c.RolePermissionsComponent
      ),
  },
  {
    path: 'entities',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/entities/entities.component').then(
        (c) => c.EntitiesComponent
      ),
  },
  {
    path: 'entity',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/entity/entity.component').then(
        (c) => c.EntityComponent
      ),
  },
  {
    path: 'entity/:id',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/entity/entity.component').then(
        (c) => c.EntityComponent
      ),
  },
  {
    path: 'permission',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/permission/permission.component').then(
        (c) => c.PermissionComponent
      ),
  },
  {
    path: 'permission/:id',
    canActivate: [MsalGuard, PermissionsGuard],
    loadComponent: () =>
      import('./components/permission/permission.component').then(
        (c) => c.PermissionComponent
      ),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];
