import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../../services/security.service';
import { User } from '../../models/user.model';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: [],
  standalone: true,
})
export class ProfileComponent implements OnInit {
  profile!: User;

  constructor(
    private securityService: SecurityService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.securityService
      .getCurrentUser()
      .then((user) => (this.profile = user))
      .catch((error) => this.notificationsService.error(error));
  }
}
